import React from 'react'
import ArtPictures from '../img/art-pictures.jpg'
import TvWall from '../img/tv-wall.jpg'
import Mirror from '../img/mirror-object.jpg'
import Office from '../img/office-setup.jpg'
import Shelf from '../img/shelf-storage.jpg'
import Curtains from '../img/curtains.jpg'
import ClientReviews from './ClientReviews'
import { Link } from 'react-router-dom'
import CallToAction from './CallToAction'

export default function HangingDrilling() {
  return (
    <div>
      {/* haeder start */}
      <div className="all-header-bg-drill">
        <div className="all-header-overlay">
          <div className="container-fluid">
            <div className="row justify-content-center">
              <div className="col-md-4 py-6">
                <h1 className='text-center'>Professional Electric Services</h1>
                <p className='text-white text-center'>Electric Service by our Expert team in Dubai</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* haeder end */}

      {/* start */}
      <div className="container pt-3">
        {/* <h1 className='text-center'>Expert Drilling and Hanging Services</h1> */}
        <div className="row">
          <div className="col-md-12">
            <p>We provide a comprehensive range of electric services designed to meet all your residential and commercial needs. Our team of skilled electricians is dedicated to delivering high-quality workmanship and reliable solutions for all your electrical requirements. From lights and chandelier installation to switch socket fittings, we ensure your electrical systems function efficiently and safely.</p>
            {/* start */}
            <div className="container pt-3">
              <h1 className='text-center'>Our Electric Services</h1>
              <div className="row align-items-center pt-3">
                <div className="col-md-12">
                  <div className="row g-3 pseduoCardRow">
                    <div className="col-md-6">
                      <div className="card">
                        <div className="card-body">
                          {/* <img src={ArtPictures} alt="villa painting services" className='img-fluid mb-2' /> */}
                          <h3>Lights and Chandelier Installation</h3>
                          <p>Illuminate your space with our expert lights and chandelier installation services. We handle all types of lighting fixtures, ensuring safe and secure installations that enhance the ambiance of your home or office.</p>
                          <p>Our services include:
                            <ul>
                              <li>•	Chandelier Installation</li>
                              <li>•	Pendant Light Installation</li>
                              <li>•	Ceiling Light Installation</li>
                              <li>•	Outdoor Lighting Installation</li>
                            </ul>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="card">
                        <div className="card-body">
                          {/* <img src={TvWall} alt="villa painting services" className='img-fluid mb-2' /> */}
                          <h3>LED Light and Spot Lights Installation</h3>
                          <p>Upgrade to energy-efficient lighting with our LED light and spot lights installation services. We provide high-quality installations that improve lighting efficiency and reduce energy costs.</p>
                          <p>Our services include:
                            <ul>
                              <li>•	LED Light Installation</li>
                              <li>•	Spot Light Installation</li>
                              <li>•	Under-Cabinet Lighting</li>
                              <li>•	Accent Lighting</li>
                            </ul>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="card">
                        <div className="card-body">
                          {/* <img src={TvWall} alt="villa painting services" className='img-fluid mb-2' /> */}
                          <h3>Switch Socket Installation</h3>
                          <p>Ensure safe and reliable electrical connections with our professional switch socket installation services. We provide comprehensive solutions for all your electrical outlet needs.</p>
                          <p>Our services include:
                            <ul>
                              <li>•	Standard Switch Socket Installation</li>
                              <li>•	USB Outlet Installation</li>
                              <li>•	Outdoor Socket Installation</li>
                              <li>•	Custom Electrical Solutions</li>
                            </ul>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* end */}
            <p className='mt-3'>We offer competitive pricing for all our Electric services, providing excellent value without compromising on quality. Get a free quote today and see how affordable our services can be.</p>
          </div>
        </div>
      </div>
      {/* end */}

      {/* start */}
      <div className="container pt-3">
        <h1 className='text-center'>Why Us For Electric Services?</h1>
        <div className="row align-items-center pt-3">
          <div className="col-md-12">
            <div className="row g-3 pseduoCardRow">
              <div className="col-md-6">
                <div className="card">
                  <div className="card-body">
                    <h3>Experienced Professionals</h3>
                    <p>Our team consists of skilled and experienced electric field staff who bring expertise and attention to detail to every project. We ensure all work is done to the highest standards.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="card">
                  <div className="card-body">
                    <h3>Quality Tools and Materials</h3>
                    <p>We use only high-quality materials and tools to ensure durability and longevity in all our Electric projects. This commitment to quality guarantees lasting results and customer satisfaction.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="card">
                  <div className="card-body">
                    <h3>Customized Solutions</h3>
                    <p>We understand that every project is unique. Our services are tailored to meet your specific needs, providing personalized solutions that fit your space and style.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="card">
                  <div className="card-body">
                    <h3>Affordable Pricing</h3>
                    <p>We offer competitive pricing without compromising on quality. Our transparent pricing ensures you get excellent value for your investment, with no hidden costs.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="col-md-6">
            <img src={HangingDrillingImg} alt="villa painting services" className='img-fluid' />
          </div> */}
        </div>
      </div>
      {/* end */}

      {/* start */}
      <div className='mt-3 benefits-work-bg'>
        <div className="container">
          <div className="row py-3">
            <div className="col-md-12">
              <h1 className='text-center' style={{ color: "var(--grayish-color)" }}>Benefits of Hiring an Expert Handyman for Furniture Fixation and Repair</h1>
              <div className="row g-3 mt-2">
                <div className="col-md-6">
                  <div className="card">
                    <div className="card-body">
                      <h3>Professional Expertise</h3>
                      <p>Expert handymen have the skills and experience needed to handle a wide range of furniture repairs and fixations. They know the best techniques and tools to use for different types of furniture. Professionals can quickly identify the root cause of the problem and provide effective solutions, ensuring your furniture is fixed correctly the first time.</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="card">
                    <div className="card-body">
                      <h3>Quality Results</h3>
                      <p>Handymen use high-quality materials and methods to ensure repairs are long-lasting and reliable. Professional repairs can restore the original look of your furniture, making it look as good as new.</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="card">
                    <div className="card-body">
                      <h3>Time and Cost Efficiency</h3>
                      <p>An expert handyman can complete repairs much faster than an untrained individual, saving you valuable time. Hiring a professional can be more cost-effective in the long run, as their repairs are durable, reducing the need for future fixes. It also eliminates the risk of further damage caused by improper repairs.</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="card">
                    <div className="card-body">
                      <h3>Increased Furniture Lifespan</h3>
                      <p>Quality repairs and fixations extend the lifespan of your furniture, allowing you to enjoy your investment for many more years. Proper maintenance and repair preserve the value of your furniture, which is especially important for high-end or antique pieces.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <h1 className='text-center' style={{ color: "var(--grayish-color)" }}>How It Works?</h1>
              <div className="row g-3 mt-2">
                <div className="col-md-6">
                  <div className="card">
                    <div className="card-body">
                      <h3>Consultation</h3>
                      <p>Begin with a personalized consultation to discuss your vision, preferred color schemes, and any specific requirements for your villa painting project.</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="card">
                    <div className="card-body">
                      <h3>Surface Assessment</h3>
                      <p>Our team conducts a thorough assessment of the villa’s exterior, identifying any repairs or preparations or preparations needed to ensure a smooth and durable finish.</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="card">
                    <div className="card-body">
                      <h3>Color Selection</h3>
                      <p>Choose from a wide range of colors or receive expert guidance to create a customized palette that suits your villa’s architecture and your personal style.</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="card">
                    <div className="card-body">
                      <h3>Professional Painting</h3>
                      <p>Our skilled painters execute the project with precision, ensuring that every inch of your villa is coated with expertise for a stunning and enduring result.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* end */}

      {/* start */}
      <div className="container pt-3">
        <div className="row">
          <h1 className='text-center'>Painting Services</h1>
          <p className='text-center'>Transform your spaces with precision and artistry through Home Painting expert painting services.</p>
          <div className="col-md-3">
            <div className="card">
              <div className="card-body">
                <h3 className='text-center'>Office Painting</h3>
                <div className="d-flex justify-content-center">
                  <Link to='/office-painting'><i className='fas fa-arrow-right'></i> Office Painting</Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="card">
              <div className="card-body">
                <h3 className='text-center'>House Painting</h3>
                <div className="d-flex justify-content-center">
                  <Link to="/house-painting"><i className='fas fa-arrow-right'></i> House Painting</Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="card">
              <div className="card-body">
                <h3 className='text-center'>Epoxy Floring</h3>
                <div className="d-flex justify-content-center">
                  <Link to="/epoxy-floring"><i className='fas fa-arrow-right'></i> Epoxy Floring</Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="card">
              <div className="card-body">
                <h3 className='text-center'>Wallpaper Install</h3>
                <div className="d-flex justify-content-center">
                  <Link to="/wallpaper-installation"><i className='fas fa-arrow-right'></i> Wallpaper Install</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* end */}

      <ClientReviews />
      <CallToAction />
    </div >
  )
}
