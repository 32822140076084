import React from 'react'
import ArtPictures from '../img/art-pictures.jpg'
import TvWall from '../img/tv-wall.jpg'
import Mirror from '../img/mirror-object.jpg'
import Office from '../img/office-setup.jpg'
import Shelf from '../img/shelf-storage.jpg'
import Curtains from '../img/curtains.jpg'
import ClientReviews from './ClientReviews'
import { Link } from 'react-router-dom'
import CallToAction from './CallToAction'

export default function HangingDrilling() {
  return (
    <div>
      {/* haeder start */}
      <div className="all-header-bg-drill">
        <div className="all-header-overlay">
          <div className="container-fluid">
            <div className="row justify-content-center">
              <div className="col-md-4 py-6">
                <h1 className='text-center'>Expert Plumbing Services</h1>
                <p className='text-white text-center'>Affordable Plumbing Service in Dubai</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* haeder end */}

      {/* start */}
      <div className="container pt-3">
        {/* <h1 className='text-center'>Expert Drilling and Hanging Services</h1> */}
        <div className="row">
          <div className="col-md-12">
            <p>We offer a comprehensive range of plumbing services designed to meet all your residential and commercial needs. Our team of skilled plumbers is dedicated to providing high-quality workmanship and reliable solutions for all your plumbing requirements. From water heater installations to water tank cleaning, we ensure your plumbing systems function efficiently and effectively.</p>
            {/* start */}
            <div className="container pt-3">
              <h1 className='text-center'>Our Plumbing Services</h1>
              <div className="row align-items-center pt-3">
                <div className="col-md-12">
                  <div className="row g-3 pseduoCardRow">
                    <div className="col-md-6">
                      <div className="card">
                        <div className="card-body">
                          {/* <img src={ArtPictures} alt="villa painting services" className='img-fluid mb-2' /> */}
                          <h3>Water Heater Installation</h3>
                          <p>Ensure a consistent supply of hot water with our professional water heater installation services. We handle all types of water heaters, ensuring they are installed safely and correctly for optimal performance.</p>
                          <p>Our services include:
                            <ul>
                              <li>•	Tankless Water Heater Installation</li>
                              <li>•	Storage Water Heater Installation</li>
                              <li>•	Solar Water Heater Installation</li>
                              <li>•	Gas Water Heater Installation</li>
                            </ul>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="card">
                        <div className="card-body">
                          {/* <img src={TvWall} alt="villa painting services" className='img-fluid mb-2' /> */}
                          <h3>Washing Machine & Dishwasher Installation</h3>
                          <p>Get your appliances up and running smoothly with our expert washing machine and dishwasher installation services. We ensure proper connections and functionality to prevent leaks and ensure optimal performance.</p>
                          <p>Our services include:
                            <ul>
                              <li>•	Washing Machine</li>
                              <li>•	Dishwasher Installation</li>
                              <li>•	Built-in Appliance Installation</li>
                              <li>•	Laundry Room Setup</li>
                            </ul>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="card">
                        <div className="card-body">
                          {/* <img src={TvWall} alt="villa painting services" className='img-fluid mb-2' /> */}
                          <h3>Taps Replacement</h3>
                          <p>Upgrade or repair your taps with our professional tap replacement services. We provide quick and efficient replacements to prevent leaks and improve water flow.</p>
                          <p>Our services include:
                            <ul>
                              <li>•	Kitchen Tap Replacement</li>
                              <li>•	Bathroom Tap Replacement</li>
                              <li>•	Outdoor Tap Replacement</li>
                              <li>•	Custom Tap Installations</li>
                            </ul>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="card">
                        <div className="card-body">
                          {/* <img src={TvWall} alt="villa painting services" className='img-fluid mb-2' /> */}
                          <h3>Water Tank Cleaning</h3>
                          <p>Ensure clean and safe water storage with our comprehensive water tank cleaning services. We remove sediment, bacteria, and other contaminants to maintain the purity of your water supply.</p>
                          <p>Our services include:
                            <ul>
                              <li>•	Residential Water Tank Cleaning</li>
                              <li>•	Commercial Water Tank Cleaning</li>
                              <li>•	Regular Maintenance Plans</li>
                              <li>•	Emergency Cleaning Services</li>
                            </ul>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="card">
                        <div className="card-body">
                          {/* <img src={TvWall} alt="villa painting services" className='img-fluid mb-2' /> */}
                          <h3>Toilet Flush Tank Fitting Replacement</h3>
                          <p>Maintain efficient and reliable toilet functionality with our flush tank fitting replacement services. We handle all types of flush tank issues to ensure smooth and effective operation.</p>
                          <p>Our services include:
                            <ul>
                              <li>•	Standard Flush Tank Replacement</li>
                              <li>•	Dual Flush Tank Installation</li>
                              <li>•	Custom Flush Tank Solutions</li>
                              <li>•	Leak Repair and Prevention</li>
                            </ul>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* end */}
            <p className='mt-3'>Ready to solve your plumbing issues with our professional services? Contact Home to Home today for a free consultation and quote. Let us handle all your plumbing needs, ensuring efficient and reliable solutions for your home or business.</p>
          </div>
        </div>
      </div>
      {/* end */}

      {/* start */}
      <div className="container pt-3">
        <h1 className='text-center'>Why Us For Plumbing?</h1>
        <div className="row align-items-center pt-3">
          <div className="col-md-12">
            <div className="row g-3 pseduoCardRow">
              <div className="col-md-6">
                <div className="card">
                  <div className="card-body">
                    <h3>Experienced Professionals</h3>
                    <p>Our team consists of skilled and experienced Plumbers who bring expertise and attention to detail to every project. We ensure all work is done to the highest standards.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="card">
                  <div className="card-body">
                    <h3>Quality Tools and Materials</h3>
                    <p>We use only high-quality materials and tools to ensure durability and longevity in all our Plumbing projects. This commitment to quality guarantees lasting results and customer satisfaction.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="card">
                  <div className="card-body">
                    <h3>Customized Solutions</h3>
                    <p>We understand that every project is unique. Our services are tailored to meet your specific needs, providing personalized solutions that fit your space and style.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="card">
                  <div className="card-body">
                    <h3>Affordable Pricing</h3>
                    <p>We offer competitive pricing without compromising on quality. Our transparent pricing ensures you get excellent value for your investment, with no hidden costs.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="col-md-6">
            <img src={HangingDrillingImg} alt="villa painting services" className='img-fluid' />
          </div> */}
        </div>
      </div>
      {/* end */}

      {/* start */}
      <div className='mt-3 benefits-work-bg'>
        <div className="container">
          <div className="row py-3">
            <div className="col-md-12">
              <h1 className='text-center' style={{ color: "var(--grayish-color)" }}>Benefits of Hiring a Professional Plumbing Handyman</h1>
              <div className="row g-3 mt-2">
                <div className="col-md-6">
                  <div className="card">
                    <div className="card-body">
                      <h3>Professional Expertise</h3>
                      <p>Professional plumbers have the necessary training, skills, and experience to handle a wide range of plumbing issues. They understand the complexities of plumbing systems and can diagnose problems accurately. Experienced plumbers use the latest tools and techniques to provide efficient and effective solutions, ensuring high-quality results.</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="card">
                    <div className="card-body">
                      <h3>Quality Workmanship</h3>
                      <p>Professionals provide durable and reliable repairs that stand the test of time, reducing the need for frequent fixes. Correct installation of plumbing systems and fixtures ensures optimal performance and prevents future issues.</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="card">
                    <div className="card-body">
                      <h3>Time and Cost Efficiency</h3>
                      <p>Professional plumbers can quickly identify and resolve plumbing issues, saving you time and minimizing disruption to your daily routine. By preventing further damage and providing long-lasting repairs, professional plumbers can save you money in the long run. Additionally, they offer competitive pricing and prevent costly mistakes associated with DIY repairs.</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="card">
                    <div className="card-body">
                      <h3>Safety</h3>
                      <p>Professional plumbers follow safety protocols to protect themselves and your property during repairs and installations. Proper plumbing work prevents water damage, mold growth, and other hazards that can arise from faulty plumbing systems.</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="card">
                    <div className="card-body">
                      <h3>Use of Advance Tools</h3>
                      <p>Professionals use advanced tools and equipment that are often not available to the general public, allowing them to perform their work more efficiently and accurately. The use of specialized tools ensures precise and accurate work, resulting in better performance and fewer issues.</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="card">
                    <div className="card-body">
                      <h3>Long-Term Maintenance</h3>
                      <p>Professional plumbers can provide regular maintenance services to keep your plumbing systems in top condition, preventing major issues before they occur. They can offer expert advice on maintaining your plumbing systems and recommend upgrades or improvements to enhance efficiency and performance.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <h1 className='text-center' style={{ color: "var(--grayish-color)" }}>How It Works?</h1>
              <div className="row g-3 mt-2">
                <div className="col-md-6">
                  <div className="card">
                    <div className="card-body">
                      <h3>Consultation</h3>
                      <p>Begin with a personalized consultation to discuss your vision, preferred color schemes, and any specific requirements for your villa painting project.</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="card">
                    <div className="card-body">
                      <h3>Surface Assessment</h3>
                      <p>Our team conducts a thorough assessment of the villa’s exterior, identifying any repairs or preparations or preparations needed to ensure a smooth and durable finish.</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="card">
                    <div className="card-body">
                      <h3>Color Selection</h3>
                      <p>Choose from a wide range of colors or receive expert guidance to create a customized palette that suits your villa’s architecture and your personal style.</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="card">
                    <div className="card-body">
                      <h3>Professional Painting</h3>
                      <p>Our skilled painters execute the project with precision, ensuring that every inch of your villa is coated with expertise for a stunning and enduring result.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* end */}

      {/* start */}
      <div className="container pt-3">
        <div className="row">
          <h1 className='text-center'>Painting Services</h1>
          <p className='text-center'>Transform your spaces with precision and artistry through Home Painting expert painting services.</p>
          <div className="col-md-3">
            <div className="card">
              <div className="card-body">
                <h3 className='text-center'>Office Painting</h3>
                <div className="d-flex justify-content-center">
                  <Link to='/office-painting'><i className='fas fa-arrow-right'></i> Office Painting</Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="card">
              <div className="card-body">
                <h3 className='text-center'>House Painting</h3>
                <div className="d-flex justify-content-center">
                  <Link to="/house-painting"><i className='fas fa-arrow-right'></i> House Painting</Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="card">
              <div className="card-body">
                <h3 className='text-center'>Epoxy Floring</h3>
                <div className="d-flex justify-content-center">
                  <Link to="/epoxy-floring"><i className='fas fa-arrow-right'></i> Epoxy Floring</Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="card">
              <div className="card-body">
                <h3 className='text-center'>Wallpaper Install</h3>
                <div className="d-flex justify-content-center">
                  <Link to="/wallpaper-installation"><i className='fas fa-arrow-right'></i> Wallpaper Install</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* end */}

      <ClientReviews />
      <CallToAction />
    </div >
  )
}
