import React from 'react'
import ArtPictures from '../img/art-pictures.jpg'
import TvWall from '../img/tv-wall.jpg'
import Mirror from '../img/mirror-object.jpg'
import Office from '../img/office-setup.jpg'
import Shelf from '../img/shelf-storage.jpg'
import Curtains from '../img/curtains.jpg'
import ClientReviews from './ClientReviews'
import { Link } from 'react-router-dom'
import CallToAction from './CallToAction'

export default function HangingDrilling() {
  return (
    <div>
      {/* haeder start */}
      <div className="all-header-bg-drill">
        <div className="all-header-overlay">
          <div className="container-fluid">
            <div className="row justify-content-center">
              <div className="col-md-4 py-6">
                <h1 className='text-center'>Hanging & Drilling Services</h1>
                <p className='text-white text-center'>Expert Drilling and Hanging Services</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* haeder end */}

      {/* start */}
      <div className="container pt-3">
        {/* <h1 className='text-center'>Expert Drilling and Hanging Services</h1> */}
        <div className="row">
          <div className="col-md-12">
            <p>Are you looking for reliable and professional hanging and drilling services, we specialize in providing top-quality solutions for all your installation needs. From repairs and installations to painting and carpentry, our skilled handymen provide prompt, efficient, and affordable services to keep your property in top condition. Contact us today for fast and reliable handyman services you can trust.</p>
            {/* start */}
            <div className="container pt-3">
              <h1 className='text-center'>Our Hanging & Drilling Services</h1>
              <div className="row align-items-center pt-3">
                <div className="col-md-12">
                  <div className="row g-3 pseduoCardRow">
                    <div className="col-md-6">
                      <div className="card">
                        <div className="card-body">
                          <img src={ArtPictures} alt="villa painting services" className='img-fluid mb-2' />
                          <h3>Art and Picture Hanging</h3>
                          <p>Transform your home or office with perfectly hung art and pictures. We ensure your artwork is displayed beautifully and securely, at the ideal height and alignment.</p>
                          <p>Our services include:
                            <ul>
                              <li>•	Single picture hanging</li>
                              <li>•	Gallery wall arrangement</li>
                              <li>•	Heavy artwork installation</li>
                            </ul>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="card">
                        <div className="card-body">
                          <img src={TvWall} alt="villa painting services" className='img-fluid mb-2' />
                          <h3>TV Wall Mounting</h3>
                          <p>Get your TV mounted safely and securely with our professional TV wall mounting services. We handle all types of TVs and wall mounts, ensuring optimal viewing angles and cable management.</p>
                          <ul>
                            <li>•	Flat-screen TV mounting</li>
                            <li>•	Swivel and tilt mount installation</li>
                            <li>•	Concealed wiring</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="card">
                        <div className="card-body">
                          <img src={Shelf} alt="villa painting services" className='img-fluid mb-2' />
                          <h3>Shelf and Storage Installation</h3>
                          <p>Maximize your space with custom shelf and storage solutions. We install shelves, cabinets, and other storage units with precision and care, ensuring they are level and secure.</p>
                          <ul>
                            <li>•	Floating shelf installation</li>
                            <li>•	Bookcase and cabinet mounting</li>
                            <li>•	Closet and pantry organization systems</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="card">
                        <div className="card-body">
                          <img src={Mirror} alt="villa painting services" className='img-fluid mb-2' />
                          <h3>Mirror and Heavy Object Mounting</h3>
                          <p>Ensure your mirrors and heavy objects are securely mounted with our expert services. We use the appropriate anchors and supports to handle heavy items safely.</p>
                          <ul>
                            <li>•	Large mirror hanging</li>
                            <li>•	Heavy decor and sculpture mounting</li>
                            <li>•	Safe and secure installations</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="card">
                        <div className="card-body">
                          <img src={Curtains} alt="villa painting services" className='img-fluid mb-2' />
                          <h3>Curtain Rod and Blinds Installation</h3>
                          <p>Achieve the perfect window treatment setup with our curtain rod and blinds installation services. We ensure your rods and blinds are installed evenly and functionally.</p>
                          <ul>
                            <li>•	Curtain rod hanging</li>
                            <li>•	Blind and shade installation</li>
                            <li>•	Drapery hardware setup</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="card">
                        <div className="card-body">
                          <img src={Office} alt="villa painting services" className='img-fluid mb-2' />
                          <h3>Home Office Setup</h3>
                          <p>Create an efficient and organized workspace with our home office setup services. We help you install everything you need to optimize your work environment.</p>
                          <ul>
                            <li>•	Whiteboard and corkboard installation</li>
                            <li>•	Floating desk and shelving units</li>
                            <li>•	Cable management and organization</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* end */}
            <p className='mt-3'>We offer competitive pricing for all our hanging and drilling services, providing excellent value without compromising on quality. Get a free quote today and see how affordable our services can be.</p>
          </div>
        </div>
      </div>
      {/* end */}

      {/* start */}
      <div className="container pt-3">
        <h1 className='text-center'>Why Us For Hanging & Drilling?</h1>
        <div className="row align-items-center pt-3">
          <div className="col-md-12">
            <div className="row g-3 pseduoCardRow">
              <div className="col-md-6">
                <div className="card">
                  <div className="card-body">
                    <h3>Experienced Professionals</h3>
                    <p>Our team of experienced professionals has the skills and expertise to handle all types of hanging and drilling tasks. We pay attention to every detail to ensure perfect results.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="card">
                  <div className="card-body">
                    <h3>Quality Tools and Materials</h3>
                    <p>We use high-quality tools and materials to ensure all installations are secure and long-lasting. From heavy-duty anchors to precise drilling tools, we are equipped for any job.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="card">
                  <div className="card-body">
                    <h3>Customized Solutions</h3>
                    <p>Every space is unique, and so are your needs. We provide customized solutions tailored to your specific requirements, ensuring functionality and aesthetics.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="card">
                  <div className="card-body">
                    <h3>Customer Satisfaction</h3>
                    <p>Your satisfaction is our top priority. We work diligently to meet your expectations and provide a seamless experience from start to finish. Our numerous happy clients are a testament to our dedication and quality of service.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="col-md-6">
            <img src={HangingDrillingImg} alt="villa painting services" className='img-fluid' />
          </div> */}
        </div>
      </div>
      {/* end */}

      {/* start */}
      <div className='mt-3 benefits-work-bg'>
        <div className="container">
          <div className="row py-3">
            <div className="col-md-12">
              <h1 className='text-center' style={{ color: "var(--grayish-color)" }}>Benefits of Proper Wall Hanging and Drilling</h1>
              <div className="row g-3 mt-2">
                <div className="col-md-6">
                  <div className="card">
                    <div className="card-body">
                      <h3>Enhanced Aesthetic Appeal</h3>
                      <p>Professionally hung items are positioned at the ideal height and alignment, enhancing the visual appeal of your space. Proper hanging techniques create a harmonious and balanced look, making your decor more cohesive and pleasing to the eye.</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="card">
                    <div className="card-body">
                      <h3>Improved Safety</h3>
                      <p>Proper drilling and use of the correct anchors ensure that heavy objects like TVs, mirrors, and shelves are securely mounted, reducing the risk of accidents. Correctly installed items prevent damage to walls and the objects themselves, ensuring longevity and maintaining structural integrity.</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="card">
                    <div className="card-body">
                      <h3>Optimal Functionality</h3>
                      <p>Professionally installed shelves, cabinets, and storage units make efficient use of your space, providing organized and accessible storage solutions. Proper installation ensures that functional items like TVs, curtain rods, and office setups are easy to use and positioned for maximum convenience.</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="card">
                    <div className="card-body">
                      <h3>Increased Property Value</h3>
                      <p>A well-organized and aesthetically pleasing space can increase the value of your property. Properly installed decor and functional items make your home or office more attractive to potential buyers or tenants. Secure and professional installations last longer.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <h1 className='text-center' style={{ color: "var(--grayish-color)" }}>How It Works?</h1>
              <div className="row g-3 mt-2">
                <div className="col-md-6">
                  <div className="card">
                    <div className="card-body">
                      <h3>Consultation</h3>
                      <p>Begin with a personalized consultation to discuss your vision, preferred color schemes, and any specific requirements for your villa painting project.</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="card">
                    <div className="card-body">
                      <h3>Surface Assessment</h3>
                      <p>Our team conducts a thorough assessment of the villa’s exterior, identifying any repairs or preparations or preparations needed to ensure a smooth and durable finish.</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="card">
                    <div className="card-body">
                      <h3>Color Selection</h3>
                      <p>Choose from a wide range of colors or receive expert guidance to create a customized palette that suits your villa’s architecture and your personal style.</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="card">
                    <div className="card-body">
                      <h3>Professional Painting</h3>
                      <p>Our skilled painters execute the project with precision, ensuring that every inch of your villa is coated with expertise for a stunning and enduring result.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* end */}

      {/* start */}
      <div className="container pt-3">
        <div className="row">
          <h1 className='text-center'>Painting Services</h1>
          <p className='text-center'>Transform your spaces with precision and artistry through Home Painting expert painting services.</p>
          <div className="col-md-3">
            <div className="card">
              <div className="card-body">
                <h3 className='text-center'>Office Painting</h3>
                <div className="d-flex justify-content-center">
                  <Link to='/office-painting'><i className='fas fa-arrow-right'></i> Office Painting</Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="card">
              <div className="card-body">
                <h3 className='text-center'>House Painting</h3>
                <div className="d-flex justify-content-center">
                  <Link to="/house-painting"><i className='fas fa-arrow-right'></i> House Painting</Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="card">
              <div className="card-body">
                <h3 className='text-center'>Epoxy Floring</h3>
                <div className="d-flex justify-content-center">
                  <Link to="/epoxy-floring"><i className='fas fa-arrow-right'></i> Epoxy Floring</Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="card">
              <div className="card-body">
                <h3 className='text-center'>Wallpaper Install</h3>
                <div className="d-flex justify-content-center">
                  <Link to="/wallpaper-installation"><i className='fas fa-arrow-right'></i> Wallpaper Install</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* end */}

      <ClientReviews />
      <CallToAction />
    </div >
  )
}
