import React from 'react'

export default function Footer() {
    return (
        <div className='footer-bg'>
            <div className="container pt-3">
                <div className="row">
                    <div className="col-md-4">
                        <h2 className='footer-heading mb-3'>Handyman & Painting Services</h2>
                        <p>Reliable Handyman & Painting Services in Dubai offering expert solutions for all your home and office maintenance needs. From plumbing and electrical work to carpentry and painting, we ensure quality, efficiency, and customer satisfaction. Contact us today!</p>
                    </div>
                    <div className="col-md-5">
                    
                        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14451.172502002995!2d55.1837382!3d25.1088633!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f431891b4018b%3A0xccd2e05bff91cef8!2sHome to Home Technical Services Co.!5e0!3m2!1sen!2s!4v1718219197637!5m2!1sen!2s" style={{ border: 0, width: "100%", height: "100%" }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" title='Office Map'></iframe>
                    </div>
                    <div className="col-md-3">
                        <h2 className='footer-heading mb-3'>Get In Touch</h2>
                        <p><i className='fas fa-map-marker-alt'></i> Office # BC-305, AB Business Centre - Al Barsha - Al Barsha 1 - Dubai - United Arab Emirates</p>
                        <a href="tel:+971567453487" target="blank" style={{color:"initial"}}><i className='fas fa-phone'></i> 0567453487</a>
                        <p><i className='fas fa-envelope'></i> info@hometohomepainting.com</p>
                        <h2 className='footer-heading'>Social Links</h2>
                        <div className="social-links mt-3">
                            <i className='fab fa-facebook me-2'></i>
                            <i className='fab fa-twitter me-2'></i>
                            <i className='fab fa-instagram me-2'></i>
                            <i className='fab fa-whatsapp'></i>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-bottom mt-2">
                <p className='text-center text-white'>Copyright © All rights reserved. Developed with love by <a href="https://technicmentors.com" target='blank' className='text-white' style={{ textDecoration: "underline" }}> Technic Mentors</a></p>
            </div>
        </div>
    )
}                                    
